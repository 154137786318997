<template>
    <div class="quick-view">
        <div class="quick-view-inner">
            <h2>Ci Dispiace!</h2><br>
            <h3> Puoi prenotare un singolo articolo!<br><br>
                Se vuoi prenotare un altro articolo devi fare una nuova prenotazione.
                <slot></slot>
            </h3>
            <button class="btn" @click="DataParent()" style="width: 100%;">Ho capito</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "QuickView",
    inject: ["eventBus"],

    data() {
        return {
        }
    },

    scrollToTop() {
        window.scrollTo(0, 0);
    },

    methods: {
        DataParent() {
            this.$emit('closedata', false);
        },
    },

};
</script>

<style scoped>
.quick-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;
}

.quick-view .quick-view-inner {
    width: 30vw;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
}


.quick-view .quick-view-inner h2 {
    text-align: center;
    font-size: 35px;
    color: #f38304;
}

.quick-view .quick-view-inner h3 {
    text-align: center;
    font-size: 22px;
    color: #000000ae;
}

.quick-view .quick-view-inner .btn {
    font-size: 18px;
    margin-top: 5vh;
    margin-bottom: 10px;
    width: 45%;
}

@media (max-width: 768px) {

    .quick-view .quick-view-inner {
        width: 50vw;

    }

    .quick-view .quick-view-inner h2 {
        font-size: 28px;
    }

    .quick-view .quick-view-inner h3 {
        text-align: center;
        font-size: 20px;
    }



    .quick-view .quick-view-inner .btn {
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 7px;
    }
}

@media (max-width: 576px) {
    .quick-view .quick-view-inner {
        width: 90vw;
    }

    .quick-view .quick-view-inner .btn {
        font-size: 16px;
        margin-top: 25px;
        margin-bottom: 5px;
    }

}
</style>